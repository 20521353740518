import Image from '@tiptap/extension-image';

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    'custom-emoji': {
      insertCustomEmoji: (options: { src: string; name: string }) => ReturnType;
    };
  }
}

export const CustomEmoji = Image.extend({
  name: 'custom-emoji',

  renderHTML({ HTMLAttributes }) {
    return ['span', { 'data-type': this.name, 'data-name': HTMLAttributes.alt }, ['img', HTMLAttributes]];
  },

  addCommands() {
    return {
      insertCustomEmoji:
        (options: { src: string; name: string }) =>
        ({ commands }) => {
          return commands.insertContent(
            {
              type: this.name,
              attrs: {
                src: options.src,
                alt: options.name,
              },
            },
            {
              updateSelection: true,
              parseOptions: {
                preserveWhitespace: 'full',
              },
            },
          );
        },
    };
  },
});
